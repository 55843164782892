import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'store/client/authStore';
import logo from 'assets/logo.png';
import backgroundImage from '../assets/style/img/login-bg4.png'; // Arka plan resminizi içe aktarın

export default function AuthLayout() {
  const { user } = useAuth();

  if (user) return <Navigate to="/" replace />;
  
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh',   backgroundImage: `url(${backgroundImage})`, // Arka plan resmini kullan
      backgroundSize: 'cover', // Resmin kaplamasını sağlar
      backgroundPosition: 'center'  }}>

      <div style={{textAlign: 'center' }}>
        <Outlet />
      </div>
    </div>
  );
}
