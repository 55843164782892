import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from 'components/ui/button';
import { useLogin } from 'store/server/useAuth';
import { ReloadIcon } from '@radix-ui/react-icons';
import { Link } from 'react-router-dom';

import DataTable from './database';
import DataTableBlack from './databaseblack';
import requests from 'service/api';
import { useAuth } from 'store/client/authStore';
import { toast } from 'components/ui/use-toast';

const getDataFromLocalStorage = (key) => {
  try {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : [];
  } catch (error) {
    console.error('Yerel depolamadan veri alırken hata oluştu:', error);
    return [];
  }
};

const setDataToLocalStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

function FileBlacklist() {
  const [files, setFiles] = useState([]);
  const [downloadUrl, setDownloadUrl] = useState('');
  const [differences, setDifferences] = useState([]);
  const [missingItems, setMissingItems] = useState([]);
  const [blacklist, setBlacklist] = useState([]); // Karaliste için state ekledik
  const [loading, setLoading] = useState(false); // Loading durumu için state ekledik
  const [activeTab, setActiveTab] = useState('data'); // Aktif tab için state ekledik

  const { user, accessToken } = useAuth();

  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };


  const handleSubmit2 = async (event) => {
    event.preventDefault();
    setLoading(true); // İstek başlamadan önce loading durumunu true yapıyoruz

    const bodyx = { mail: user.mail, token: accessToken }; // API isteği için gerekli parametre

    try {
      const response = await requests.order.getFromBlacklist(bodyx);
      setDataToLocalStorage('blacklist', response.data);
      setLoading(false); // İstek bittikten sonra loading durumunu false yapıyoruz
      toast.success('Güncelleme Başarılı');
    } catch (error) {
      setLoading(false); // İstek bittikten sonra loading durumunu false yapıyoruz
      toast.error(error.message);
    }
  };



  return (
    <div className="file-upload">
      <div className="tabs-container">
      <Link to="/" >
        <button
          className={`tab-button active`}
        >
        Olmayan Ürünler & Farklılıklar
        </button>
        </Link>
        <Link to="/blacklist" >

        <button
          className={`tab-button active2`}
        >
          Karaliste
        </button>
        </Link>


      </div>
      <div className="file-upload-container">
          <div style={{ display: 'grid' }}>
            <form className="file-upload-form" onSubmit={handleSubmit2}>
              <div className="buttons-container">
                <Button type="submit" className={`file-upload-button ${loading ? 'loading' : ''}`} disabled={loading}>
                  {loading && <ReloadIcon className="icon-spin" />}
                  {activeTab === 'data' ? 'Güncelle' : 'Karalisteyi Güncelle'}
                </Button>
              </div>
            </form>
            <div style={{width:"100%",marginTop:20}}>
              <DataTableBlack data={getDataFromLocalStorage('blacklist')} name="Karaliste" /> 
              
               </div>
 
          </div>

      </div>
    </div>
  );
}

export default FileBlacklist;
