import { Outlet } from 'react-router-dom'

const Root = () => {
  return (
    <div className="min-h-screen font-poppins">
      <Outlet />
    </div>
  )
}

export default Root
