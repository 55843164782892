export default function NotFoundPage() {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-simsek text-white">
      <h2 className="flex p-2 text-2xl items-center">
        <span className="border-r border-gray-50/40 py-2 pr-6">404</span>
        <span className="pl-6 text-lg font-thin">Aradığınız sayfa bulunamadı.</span>
      </h2>
    </div>
  )
}
