import { Formik, Form, Field } from 'formik'; // Field'i içe aktar
import { useLogin } from 'store/server/useAuth';
import { Button } from 'components/ui/button';
import { ReloadIcon } from '@radix-ui/react-icons';

export default function LoginForm() {
  const { mutate, isPending } = useLogin();
  

  const handleSubmit = (values) => {
    mutate(values);
  };

  return (
    <div className="login">
      <div className="row">
        <div className="login__title">
          Login
        </div>

        <Formik
          initialValues={{
            mail: '',
            password: ''
          }}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form className="login__form">
              <div className="login__inputs">
                <div className="login__box">
                  <Field type="email" name="mail" placeholder="Email ID" required className="login__input" />
                  <i className="ri-mail-fill" />
                </div>
                <div className="login__box">
                  <Field type="password" name="password" placeholder="Password" required className="login__input" />
                  <i className="ri-lock-2-fill" />
                </div>
              </div>
              <div className="login__bttm">
                <Button type="submit" className="login__button" disabled={isPending}>
                  {isPending && <ReloadIcon className="w-5 h-5 mr-2 -ml-1 animate-spin" />}
                  Giriş Yap
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>  
  );
}
