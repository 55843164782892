import React, { useState, useMemo } from 'react';
import { useTable, useGlobalFilter, useSortBy } from 'react-table';
import { useAuth } from 'store/client/authStore';
import requests from 'service/api';
import { toast } from 'components/ui/use-toast';

// Global Filter Component
const GlobalFilter = ({ globalFilter, setGlobalFilter, name }) => {
  return (
    <div className='containerxvar'>
      <span style={{ fontSize: 12, color: "white", fontWeight: 700, borderRadius: 5, padding: 10, backgroundColor: "black", borderColor: "black", borderWidth: 1 }}>
        {name}
      </span>
      <span>
        Ara:{' '}
        <input
          value={globalFilter || ''}
          onChange={e => setGlobalFilter(e.target.value || undefined)}
          placeholder="Metin Ara..."
          style={{ fontSize: '15px', margin: '10px', borderWidth: 1, borderColor: "black", borderRadius: 10, padding: 5 }}
        />
      </span>
    </div>
  );
};

const DataTableBlack = ({ data, name }) => {
  const { user, accessToken } = useAuth(); // useAuth hook'u bileşen gövdesinde çağrılmalı
  const [tableData, setTableData] = useState(data);

  const handleBlacklist = async (item) => {
    const isConfirmed = window.confirm('Bu ürünü karalisteden çıkarmak istediğinizden emin misiniz?');
    if (isConfirmed) {
      console.log(item.source);

      const bodyx = {
        mail: user.mail,
        token: accessToken,
        product_id: item.barkod || '', // Eğer source "n11" ise boş, değilse item.barkod
        reason: item.stokKodu || '', // Eğer varyasyonId undefined ise boş string
        source: item.source
      };

      try {
        const response = await requests.order.removeFromBlacklist(bodyx);
        console.log(response.message);
        toast.success('Ürün başarıyla karalisteden çıkarıldı.');

        // Tablo verilerini güncelleme
        setTableData(prevData => prevData.filter(dataItem => dataItem.barkod !== item.barkod));
      } catch (error) {
        toast.error('Karalisteden çıkarma hatası:', error.message);
      }
    }
  };

  const columns = useMemo(
    () => [
      { Header: 'Kaynak', accessor: 'source' },
      { Header: 'Tici Barkod', accessor: 'barkod' },
      { Header: 'Stok Kodu', accessor: 'stokKodu' },
      {
        Header: 'İşlem',
        accessor: 'actions',
        Cell: ({ row }) => (
          row.original.source ? (
            <button style={{ backgroundColor: "black", color: "white", padding: 4, position: "relative", zIndex: 2, borderRadius: 10 }} onClick={() => handleBlacklist(row.original)}>
              Kara Listeden Kaldır
            </button>
          ) : null
        )
      }
    ],
    []
  );

  const filledData = useMemo(() => {
    const emptyRows = Array.from({ length: 10 - tableData.length }, () => ({
      source: '',
      barkod: '',
      varyasyonId: ''
    }));
    return tableData.length < 10 ? [...tableData, ...emptyRows] : tableData;
  }, [tableData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data: filledData,
      initialState: {
        sortBy: [
          {
            id: 'source',
            desc: false
          }
        ]
      }
    },
    useGlobalFilter, // Use global filter plugin hook
    useSortBy // Use sorting plugin hook
  );

  return (
    <div className='custom-scroll' style={{
      maxHeight: '70vh',
      overflowY: 'auto',
      borderRadius: 10,
      backgroundColor: 'white',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      paddingLeft: 15,
      paddingRight: 15
    }}>
      <GlobalFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} name={name} />
      <div>
        <table {...getTableProps()} style={{ width: '100%' }}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{ borderBottom: 'solid 3px green', background: 'aliceblue', color: 'black', fontSize: 14 }}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} style={{ cursor: 'pointer' }}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()} style={{ padding: '5px', border: 'solid 1px gray', background: '#f9f9f9' }}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DataTableBlack;
