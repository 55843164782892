import { useMutation } from '@tanstack/react-query'
import { toast } from 'components/ui/use-toast'

import requests from 'service/api'
import { useAuth } from 'store/client/authStore'

export const useLogin = () => {

  const { setUser, setAccessToken } = useAuth()

  return useMutation({
    mutationFn: (data) => requests.auth.login(data),
    onSuccess: ({ data }) => {
      setUser({
        id: data.id,
        mail: data.mail,
        tierType:data.tierType
      })
      setAccessToken(data.token)

    },
    onError: (error) => {
      toast.error(error.response.data.message)

    }
  })
}
