import { Route, Routes } from 'react-router-dom'
import { Navigate } from 'react-router-dom'

import NotFoundPage from 'pages/NotFoundPage'
import ProtectedRoutes from 'ProtectedRoutes'

//Layouts
import Root from 'layouts/Root'
import DashboardLayout from 'layouts/DashboardLayout'
import AuthLayout from 'layouts/AuthLayout'
import FileUpload from 'pages/dashboard/fileupload'
import FileBlacklist from 'pages/dashboard/fileblacklist'
//Auth
import LoginPage from 'pages/(auth)/Login'
import './assets/style/scss/styles.scss';

const AllRoutes = () => {
  return (
    <Routes>
      <Route element={<Root />}>
        <Route index element={<Navigate to={'anasayfa'} replace />} />
        <Route element={<ProtectedRoutes />}>

        <Route element={<DashboardLayout />}>
            <Route path="anasayfa" element={<FileUpload />} />
            <Route path="blacklist" element={<FileBlacklist />} />

        </Route>
        </Route>
        <Route element={<AuthLayout />}>
          <Route path="girisyap" element={<LoginPage />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  )
}

export default AllRoutes
