import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from 'components/ui/button';
import { useLogin } from 'store/server/useAuth';
import { ReloadIcon } from '@radix-ui/react-icons';
import DataTable from './database';
import DataTableBlack from './databaseblack';
import requests from 'service/api';
import { useAuth } from 'store/client/authStore';
import { toast } from 'components/ui/use-toast';
import { Link } from 'react-router-dom';

const getDataFromLocalStorage = (key) => {
  try {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : [];
  } catch (error) {
    console.error('Yerel depolamadan veri alırken hata oluştu:', error);
    return [];
  }
};

const setDataToLocalStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

function FileUpload() {
  const [files, setFiles] = useState([]);
  const [downloadUrl, setDownloadUrl] = useState('');
  const [differences, setDifferences] = useState([]);
  const [missingItems, setMissingItems] = useState([]);
  const [blacklist, setBlacklist] = useState([]); // Karaliste için state ekledik
  const [loading, setLoading] = useState(false); // Loading durumu için state ekledik
  const [activeTab, setActiveTab] = useState('data'); // Aktif tab için state ekledik

  const [platforms, setPlatforms] = useState({
    N11: true,
    Pazarama: true,
    Trendyol: true,
    Ciceksepeti: true,
    PttAvm: true
  });

  const { user, accessToken } = useAuth();

  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };
  function kalanSureBul(metin) {
    const pattern = /Kalan Süre: \d+ saniye/;
    const match = metin.match(pattern);
    if (match) {
        return match[0];
    }
    return null;
}
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // İstek başlamadan önce loading durumunu true yapıyoruz

    const bodyx = {
      mail: user.mail,
      token: accessToken,
      platforms: platforms // Add platform states to the body
    };
    try {
      const response = await requests.order.getAllApiData(bodyx);
      setDataToLocalStorage('missing', response.data.missingProducts);
      setDataToLocalStorage('differences', response.data.stockMismatchProducts);
      console.log("ahmet"+JSON.stringify(response.data));

      setLoading(false); // İstek bittikten sonra loading durumunu false yapıyoruz
      toast.success('Güncelleme Başarılı');
    } catch (error) {
      setLoading(false); // İstek bittikten sonra loading durumunu false yapıyoruz
      const kalanSure = kalanSureBul(error.response.data);

      toast.error(kalanSure);
    }
  };

  const handlePlatformChange = (event) => {

    console.log(getDataFromLocalStorage('differences'));
    const { name, checked } = event.target;
    setPlatforms(prevState => ({ ...prevState, [name]: checked }));
  };
  useEffect(() => {
    // Tab değiştiğinde local storage'dan verileri yükle
    if (activeTab === 'data') {
      setDifferences(getDataFromLocalStorage('differences'));
      setMissingItems(getDataFromLocalStorage('missing'));
    } 
  }, [activeTab]);

  return (
    <div className="file-upload">
      <div className="tabs-container">
      <Link to="/" >
        <button
          className={`tab-button active`}
        >
        Olmayan Ürünler & Farklılıklar
        </button>
        </Link>
        <Link to="/blacklist" >

        <button
          className={`tab-button active2`}
        >
          Karaliste
        </button>
        </Link>

      </div>
      <div className="file-upload-container">
          <div style={{ display: 'grid' }}>
            <form className="file-upload-form" onSubmit={handleSubmit}>
              <div className="buttons-container">
                <Button type="submit" className={`file-upload-button ${loading ? 'loading' : ''}`} disabled={loading}>
                  {loading && <ReloadIcon className="icon-spin" />}
                  {activeTab === 'data' ? 'Güncelle' : 'Karalisteyi Güncelle'}
                </Button>
                <label>
                <input
                  type="checkbox"
                  name="N11"
                  checked={platforms.N11}
                  onChange={handlePlatformChange}
                />
                N11
              </label>
              <label>
                <input
                  type="checkbox"
                  name="Pazarama"
                  checked={platforms.Pazarama}
                  onChange={handlePlatformChange}
                />
                Pazarama
              </label>
              <label>
                <input
                  type="checkbox"
                  name="Trendyol"
                  checked={platforms.Trendyol}
                  onChange={handlePlatformChange}
                />
                Trendyol
              </label>
              <label>
                <input
                  type="checkbox"
                  name="Ciceksepeti"
                  checked={platforms.Ciceksepeti}
                  onChange={handlePlatformChange}
                />
                Çiçeksepeti
              </label>
              <label>
                <input
                  type="checkbox"
                  name="PttAvm"
                  checked={platforms.PttAvm}
                  onChange={handlePlatformChange}
                />
                PttAvm
              </label>
              </div>
            </form>
            <div className="file-upload-containerxx">
              <div className="data-table-container">
                <DataTable data={getDataFromLocalStorage('missing')} name="Olmayan Ürünler" />
              </div>
              <div className="data-table-container">
                <DataTable data={getDataFromLocalStorage('differences')} name="Farklılıklar" />
              </div>
            </div>
          </div>

      </div>
    </div>
  );
}

export default FileUpload;
