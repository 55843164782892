import { LogOutModal } from 'components/log-out';
import Logo from 'components/logo';
import { Sidebar } from 'components/sidebar';
import { Outlet } from 'react-router-dom';

export default function DashboardLayout() {
  return (
    <div
      style={{
        background: 'linear-gradient(315deg, #ffcd00 0%, #005238 74%)',
        minHeight:"100vh",
        backgroundSize: 'cover',
        position:"relative",
        paddingLeft:30,
        paddingRight:30
      }}
    >
    <div style={{position:"absolute",top:20,right:20}}>
      <LogOutModal />
      </div>
      <div>
        <main className="pt-10 pb-10">
          <Outlet />
        </main>
      </div>
    </div>
  );
}
