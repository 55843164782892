import React from 'react';
import ReactDOM from 'react-dom/client';
import 'assets/style/global.css';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Toaster } from 'components/ui/toaster';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'Routes';
import queryClient from 'config/tanstackQuery';
import dateFnsOptions from 'config/dateFns';
import { BlacklistProvider } from './pages/dashboard/Blacklistcontext';

dateFnsOptions();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
      <BlacklistProvider>

    <QueryClientProvider client={queryClient}>
      <Routes />
      <Toaster />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
    </BlacklistProvider>

  </BrowserRouter>
);

// Service worker'ı kaydedin
