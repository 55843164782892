import { QueryClient, QueryCache } from '@tanstack/react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 60 * 24 * 7
    }
  }
})

export const queryCache = new QueryCache()

export default queryClient
