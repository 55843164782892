// BlacklistContext.js
import React, { createContext, useContext, useState } from 'react';

// Context oluşturma
const BlacklistContext = createContext();

// Context Provider bileşeni
export const BlacklistProvider = ({ children }) => {
  const [blacklistData, setBlacklistData] = useState([]);
  const [blackmissingData, setblackmissingData] = useState([]);

  const [blacklistblackData, setblacklistblackData] = useState([]);


  return (
    <BlacklistContext.Provider value={{ blacklistData, setBlacklistData,blacklistblackData,setblacklistblackData ,setblackmissingData,blackmissingData}}>
      {children}
    </BlacklistContext.Provider>
  );
};

// Context kullanma kancası
export const useBlacklist = () => useContext(BlacklistContext);
