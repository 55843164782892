import React from 'react'
import Form from './form'

export default function LoginPage() {
  return (
    <div>
      <Form />
    </div>
  )
}
