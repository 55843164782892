import { create } from 'zustand'

const useAuthStore = create((set) => ({
  user: JSON.parse(localStorage.getItem('user')) || null,
  accessToken: localStorage.getItem('accessToken') || null,

  setUser: (user) => {
    set({ user })
    localStorage.setItem('user', JSON.stringify(user))
  },

  setAccessToken: (accessToken) => {
    set({ accessToken })
    localStorage.setItem('accessToken', accessToken)
  },

  logout: () => {
    localStorage.clear()
    set({ user: null, accessToken: null })
  }
}))

export const useAuth = useAuthStore
